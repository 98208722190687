import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import logo from "../images/smj-augsburg-logo.svg"

import NavbarLanding from "../components/navbar-landing"
import Gdpr from "../components/Gdpr/Gdpr"
// import Navbar from "../components/navbar"

import "../styles/index.scss"
import { FaArrowRight } from "react-icons/fa"

import Footer from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ data, location }) => (
  <div className="indexPage">
    <SEO pathname={location.pathname} />
    <header>
      <section className="hero is-dark is-medium">
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-centered is-size-1">
              <img src={logo} className="smj-logo" />
              <span>-Augsburg</span>
            </h1>
            <h2 className="subtitle has-text-centered has-text-grey-light">
              Der Jugendverein der rockt!
            </h2>
          </div>
        </div>
      </section>
    </header>
    <main className="section">
      <div className="container lift-up">
        {/* <Navbar /> */}
        <NavbarLanding />
        <div className="columns is-multiline">
          {data.allCockpitEvents.edges.map((edge, index) => (
            <div
              key={edge.node.slug.value}
              className={"column " + (index === 0 ? "is-full" : "is-4")}
            >
              <Link to={`/event/${edge.node.slug.value}`}>
                <div className={"card " + (index === 0 ? "is-horizontal" : "")}>
                  <div className="card-image">
                    <figure
                      className={
                        "image " + (index === 0 ? "is-2by1" : "is-5by3")
                      }
                    >
                      <Img
                        fluid={
                          edge.node.title_image.value.childImageSharp.fluid
                        }
                        className="project-item__image fit-image"
                      />
                    </figure>
                  </div>
                  <div className="card-content-wrapper">
                    <div className="card-content ">
                      <div className="content contain-height">
                        <h3 className="is-size-4">{edge.node.title.value}</h3>
                        <p className="is-clipped is-marginless">
                          {edge.node.summary.value}
                        </p>
                      </div>
                    </div>
                    <div className="card-content-footer has-text-grey-light">
                      <span>am {edge.node.start_date.value}</span>
                      <a className="is-pulled-right">mehr...</a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </main>

    <Footer />
    <Gdpr />
  </div>
)

export const query = graphql`
  query Event {
    allCockpitEvents {
      edges {
        node {
          slug {
            value
          }
          title {
            value
          }
          summary {
            value
          }
          start_date {
            value
          }
          title_image {
            value {
              publicURL
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
