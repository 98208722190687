import React, { useState } from "react"
import { Link } from "gatsby"

import "../styles/navbar-landing.scss"

import logo from "../images/smj-augsburg-logo.svg"
import { FaFacebookF, FaInstagram } from "react-icons/fa"

const brand = {
  color: "#ffffff",
}

const routeItem = {
  fontSize: "0.75rem",
  paddingTop: "0.68rem",
}

function NavbarLanding() {
  const [navbarIsActive, setNavbarIsActive] = useState(false)

  const toggleMenu = () => {
    setNavbarIsActive(!navbarIsActive)
  }

  return (
    <div className="route-container">
      <div className="route-group">
        <Link to="/" className="route is-uppercase has-text-grey-light">
          Home
        </Link>
        <Link
          to="/ueber-uns"
          className="route is-uppercase has-text-grey-light"
        >
          Über Uns
        </Link>
        <a
          className="route is-uppercase has-text-grey-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://shops.his-print-service.de/schoenstattjugend-augsburg"
        >
          Shop
        </a>
      </div>

      <div className="route-group">
        <a
          className="route has-text-grey-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/smj.augsburg"
        >
          <FaFacebookF />
        </a>
        <a
          className="route has-text-grey-light"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/augsburgsmj/"
        >
          <FaInstagram />
        </a>
      </div>
    </div>
  )
}

export default NavbarLanding
